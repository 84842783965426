import baseTheme from "@nextblick/gatsby-theme-hdb/src/gatsby-plugin-theme-ui";
import { merge } from "theme-ui";

// merge will deeply merge custom values with the theme's defaults
export default merge(baseTheme, {
  colors: {
    text: "#333",
    primary: "#e30505",
    background: "#fff",
    backgroundLight: "#f6f4f0",
    headerBg: "#fff",
    menuBorderColor: "#dcdcdc",
  },
  fonts: {
    body: "Hind, sans-serif",
    heading: "Hind, sans-serif",
    headingSecondary: "Cooper Lt BT, sans-serif",
  },
  sizes: {
    headerHeight: 100,
    headerLogoHeight: 60,
    subMenuHeight: 60,
  },
  text: {
    boxed: {
      backgroundColor: "transparent",
      color: "text",
      textTransform: "uppercase",
    },
    underlined: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1.5rem",

      "&::after": {
        content: '""',
        display: "block",
        width: "50%",
        height: "1px",
        background: "text",
        marginTop: "1rem",
        maxWidth: "250px",
      },
    },
    nav: {
      fontWeight: "normal",
      fontSize: "1.2rem",
    },
  },
  buttons: {
    primary: {
      variant: "text.caps",
    },
    secondary: {
      variant: "text.caps",
    },
  },
  layout: {
    cta: {
      backgroundColor: "primary",
      color: "background",
    },
  },
  styles: {
    root: {
      p: {
        fontSize: "18px",
      },
      h1: {
        textTransform: "uppercase",
      },
      h2: {
        textTransform: "uppercase",
      },
      h4: {
        fontFamily: "headingSecondary",
      },
    },
  },
});
